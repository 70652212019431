document.addEventListener("DOMContentLoaded", () => {
    const deSection = document.getElementById("de-section");
    const enSection = document.getElementById("en-section");
    const flagIcons = document.querySelectorAll(".flag-icon");

    let userLang;

    if (localStorage.getItem("preferredLang")) {
        userLang = localStorage.getItem("preferredLang");
    } else {
        userLang = (navigator.language || navigator.userLanguage).toLowerCase();
    }

    if (userLang.startsWith("de")) {
        deSection.style.display = "block";
    } else {
        enSection.style.display = "block";
    }

    flagIcons.forEach(icon => {
        icon.addEventListener("click", () => {
            const lang = icon.dataset.lang;

            if (lang === "de") {
                deSection.style.display = "block";
                enSection.style.display = "none";
            } else {
                deSection.style.display = "none";
                enSection.style.display = "block";
            }

            localStorage.setItem("preferredLang", lang);
            
            recalcReviewHeights();
        });
    });
});
